<template>
    <div class="control">
        <label class="label">{{ label }}</label>
        <textarea 
            class="textarea" 
            rows="3"
            @keydown.tab="tabCampo(tab)"
            :class="{ 'is-danger': (!preenchido && !preenchidoInput) || !preenchidoDocumento }"
            @keyup="atualizarPreenchimento($event.target.value)"
            @input="$emit('input', $event.target.value)"
        >{{ value }}</textarea>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: ['label', 'value', 'preenchido', 'tab', 'tabUltimoCampo'],
    data() {
        return {
            preenchidoInput: false,
            preenchidoDocumento: true
        }
    },
    methods: {
        /**
         * Função para verificar o preenchimento do input
         */
        atualizarPreenchimento(sValor) {
            this.preenchidoInput = true

            if(sValor == ''){
                this.preenchidoInput = false
            } 
        },
        /**
         * Função para chamar a função de tab do componente pai
         */
        tabCampo(sValor) {
            if(this.tabUltimoCampo !== undefined){
                this.tabUltimoCampo(sValor)
            }
        }
    }
}
</script>