<template>
    <div class="not-found">
        <h1 class="not-found-title">404 - Página não encontrada</h1>
        <p class="not-found-description">Desculpe, mas a página que você procura não existe.</p>
    </div>
</template>

<script>
    export default {
        name: 'Erro404',
    }
</script>

<style>
    .not-found {
        text-align: center;
    }

    .not-found-title {
        font-size: 4rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
        color: #f44336;
    }

    .not-found-description {
        font-size: 2rem;
        margin-bottom: 2rem;
        color: #616161;
    }
</style>