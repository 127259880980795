<template>
    <section>
        <i class="gg-search"></i>
        <input type="text" :placeholder="placeholder" @keyup="onKeyup" :value="value" @input="$emit('input', $event.target.value)">
    </section>
</template>

<script>
    export default {
        name: "InputFiltro",
        props: ['placeholder', 'value', 'onkeyup'],
        methods: {
            onKeyup() {
                if(typeof this.onkeyup === 'function'){
                    this.onkeyup()
                }
            }
        },
    }
</script>

<style>
    section {
        display: contents;
    }
</style>