<template>
    <div>
        <Loader />
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: 'ValidarIP',
        data() {
            return {
                validado: sessionStorage.getItem('ipValido') == 1 ? true : false
            }
        },
        mounted() {
            this.validar()
        },
        methods: {
            /**
             * Função para realizar o login na aplicação
             */
             validar() {
                window.location.href = `/login`
                // if(!this.validado){
                //     window.location.href = `/login`
                // }

                // axios.request({
                //     method: 'get',
                //     maxBodyLength: Infinity,
                //     url: `${process.env.VUE_APP_ENDPOINT_API}/v1/validarIP`
                // })
                // .then((response) => {
                //     sessionStorage.setItem('ipValido', 1)
                //     window.location.href = `/login`
                // })
                // .catch((error) => {
                //     sessionStorage.setItem('ipValido', 0)
                //     window.location.href = '/erro'
                // });
            }
        }
    }
</script>