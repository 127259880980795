<template>
    <div class="control">
        <label class="label">{{ label }}</label>
        <div class="select" :class="{ 'is-danger': !preenchido && preenchidoInput }">
            <select 
                :value="value" 
                @change="onChange" 
                @input="$emit('input', $event.target.value)"
            >
                <slot></slot>
            </select>
        </div>
        <p class="help is-danger" v-if="!preenchido && preenchidoInput">Campo obrigatório</p>
    </div>
</template>

<script>
    export default {
        name: "InputText",
        props: ['label', 'value', 'onchange', 'preenchido'],
        data() {
            return {
                preenchidoInput: typeof this.preenchido !== 'undefined' ? true : false
            }
        },
        methods: {
            onChange() {
                this.preenchidoInput = false

                if(typeof this.onchange === 'function'){
                    this.onchange()
                }
            }
        }
    }
</script>