<template>
    <div>
        <Loader v-if="carregando" />
        <div v-show="autenticado && !carregando">
            <div class="menu-mobile" v-show="menuMobile">
                <Button
                    :classes="`is-outlined is-active`" 
                    :icone="`close`" 
                    :titulo="``" 
                    style="width: 2.5rem; border-color: #ccc; margin-bottom: 2rem; border-radius: 50px;"
                    :onclick="toggleMenu"
                />
                <span style="font-size: 24px; margin: 0 3rem">Estatísticas</span>
                <span style="font-size: 24px; margin: 0 3rem">Cartoon</span>
                <span style="font-size: 24px; margin: 0 3rem">CDT-SP</span>
                <div style="display: flex; flex-direction: column; flex-wrap: wrap; gap: 2rem; margin-top: 2rem">                    
                    <Button
                        :classes="`is-green is-outlined`" 
                        :titulo="`Estatísticas`" 
                        :onclick="estatisticas"
                        style="width: 7rem; border-color: #ccc;"
                        v-if="autenticacao.admin"
                    />

                    <Button
                        :classes="`is-green is-outlined is-active`" 
                        :titulo="`Relatório`" 
                        style="width: 7rem; border-color: #ccc;"
                    /> 

                    <Button
                        :classes="`is-green is-outlined`" 
                        :titulo="`Usuários`" 
                        :onclick="usuariosPagina"
                        style="width: 7rem; border-color: #ccc;"
                        v-if="autenticacao.admin"
                    /> 
                    
                    <Button
                        :classes="`is-secondary is-outlined btn-rotate`" 
                        :icone="`software-upload`" 
                        :titulo="`Sair`" 
                        :onclick="sair"
                    />
                </div>
            </div>
            <section class="section">
                <div class="container">
                    <div class="container-principal div-cp">  
                        <div class="barra-superior">
                            <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                                <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 100px; margin-left: 1.5rem" />
                                <span style="font-size: 24px; margin: auto">Estatísticas Cartoon x CDT-SP</span>
                            </div>
                            <div style="margin-right: 2rem; display: flex; gap: 1rem">       
                                <Button
                                    :classes="`is-green is-outlined`" 
                                    :titulo="`Estatísticas`" 
                                    :onclick="estatisticas"
                                    style="width: 7rem; border-color: #ccc;"
                                    v-if="autenticacao.admin"
                                />

                                <Button
                                    :classes="`is-green is-outlined is-active`" 
                                    :titulo="`Relatório`" 
                                    style="width: 7rem; border-color: #ccc;"
                                /> 

                                <Button
                                    :classes="`is-green is-outlined`" 
                                    :titulo="`Usuários`" 
                                    :onclick="usuariosPagina"
                                    style="width: 7rem; border-color: #ccc;"
                                    v-if="autenticacao.admin"
                                /> 
                                
                                <Button
                                    :classes="`is-secondary is-outlined btn-rotate`" 
                                    :icone="`software-upload`" 
                                    :titulo="`Sair`" 
                                    :onclick="sair"
                                />
                            </div>
                        </div>
                        <div class="barra-superior barra-superior-mobile">
                            <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                                <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 100px; margin-left: 1.5rem" />
                                <div style="margin-right: 2rem; display: flex; gap: 1rem">
                                    <Button
                                        :classes="`is-outlined is-active`" 
                                        :icone="`menu`" 
                                        :titulo="``" 
                                        style="width: 2.5rem; border-color: #ccc; border-radius: 50px; padding: 18px;"
                                        :onclick="toggleMenu"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="formulario">                              
                            <div class="columns">
                                <div class="column is-full">
                                    <b style="font-size: 22px">Filtros do relatório</b>
                                </div>

                                <div class="column is-2">
                                    <InputText
                                        :label="`Data inicial`" 
                                        :tipo="`date`" 
                                        :preenchido="true"
                                        v-model="form.dataInicialLote"
                                    />
                                </div> 

                                <div class="column is-2">
                                    <InputText
                                        :label="`Data final`" 
                                        :tipo="`date`" 
                                        :preenchido="true"
                                        v-model="form.dataFinalLote"
                                    />
                                </div>  

                                <div class="column is-4">
                                    <InputText
                                        :label="`Clientes (Separe com vírgula para múltiplos clientes)`" 
                                        :preenchido="true"
                                        v-model="form.cliente"
                                        v-if="autenticacao.admin"
                                    />
                                </div> 

                                <div class="column is-4">
                                    <InputText
                                        :label="`Tipo do documento (Separe com vírgula para múltiplos tipos)`" 
                                        :preenchido="true"
                                        v-model="form.tipoDocumento"
                                        v-if="autenticacao.admin"
                                    />
                                </div>

                                <div class="column is-12" style="gap: 1rem; display: flex; justify-content: flex-end;">
                                    <Button
                                        :classes="`is-danger is-outlined btn-limpar`" 
                                        :icone="`trash`" 
                                        :titulo="`Limpar filtro`" 
                                        :onclick="limparFiltro" 
                                        :parametro1="1"
                                        v-show="pesquisado"
                                        style="width: 15rem"
                                    />
                                    <Button
                                        :classes="`is-link is-outlined btn-pesquisar`" 
                                        :icone="`search`" 
                                        :titulo="`Pesquisar`" 
                                        :onclick="pesquisar" 
                                        :parametro1="true" 
                                        :parametro2="true" 
                                        style="width: 15rem"
                                    />
                                </div>
                            </div>

                            <hr v-show="totalRegistros >= 0 || carregandoBusca" style="margin-top: -0.5rem;">

                            <div v-show="totalRegistros >= 0 || carregandoBusca" class="exportacao">
                                <div class="dropdown is-right is-hoverable" v-show="totalRegistros > 0 && !carregandoBusca">
                                    <div class="dropdown-trigger">
                                        <button class="button is-purple" @click="exportar()" aria-haspopup="true" aria-controls="dropdown-menu3">
                                            <span>Exportar CSV</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            <Loader 
                                v-if="carregandoBusca" 
                                style="margin-bottom: 2rem;"
                                :altura="20"
                            />
                                
                            <div class="columns column-table column-nao-encontrado" v-show="totalRegistros == 0 && !carregandoBusca">
                                <div>Nenhum registro encontrado</div>
                            </div>
                            
                            <Pagination 
                                :contadorPaginas.sync="contadorPaginas" 
                                :totalRegistros.sync="totalRegistros" 
                                :paginaAtual.sync="paginaAtual" 
                                :paginas.sync="paginas" 
                                :totalPaginas.sync="totalPaginas"
                                v-show="dados != false"
                            />
                            
                            <div class="columns column-table" v-show="dados != false">                                        
                                <table class="table is-fullwidth is-hoverable">
                                    <thead>
                                        <tr>
                                            <th style="text-align: center;">Código do cliente</th>
                                            <th style="text-align: center;">Total de arquivos</th>
                                            <th style="text-align: center;">Total de páginas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(dado, idx) in dados">
                                            <td style="text-align: center;">{{ dado.cartoonCodigoFatCliente }}</td>
                                            <td style="text-align: right;">{{ dado.totalArquivos ? dado.totalArquivos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}</td>
                                            <td style="text-align: right;">{{ dado.totalPaginas ? dado.totalPaginas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr style="background: #f1f1f1;">
                                            <td style="text-align: right; font-weight: bold;">Totais</td>
                                            <td style="text-align: right; font-weight: bold;">{{ contagem.totalArquivos ? contagem.totalArquivos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}</td>
                                            <td style="text-align: right; font-weight: bold;">{{ contagem.totalPaginas ? contagem.totalPaginas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr v-show="totalRegistros >= 0 || carregandoBusca" style="margin-top: -0.5rem; margin-bottom: 0.5rem;">

                            <Pagination 
                                :contadorPaginas.sync="contadorPaginas" 
                                :totalRegistros.sync="totalRegistros" 
                                :paginaAtual.sync="paginaAtual" 
                                :paginas.sync="paginas" 
                                :totalPaginas.sync="totalPaginas"
                                v-show="dados != false"
                            />
                        </div>

                        <div class="formulario footer">
                            <p style="margin-left: -0.2rem; font-size: 12px;">v1.3.2 - Página atualizada às {{ horaAtual }}</p>
                            
                            <Button
                                :classes="`is-${contagemRegressivaClass} btn-small btn-sessao`" 
                                style="margin-right: -45px; width: 16rem;"
                                :onclick="refreshToken"
                                :titulo="`${contagemRegressiva}`" 
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalLimpeza }">
            <div class="modal-background" @click="modalLimpeza = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Limpar o filtro</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalLimpeza = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente limpar o filtro?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalLimpeza = false">Não</button>
                    <button class="button is-danger is-outlined" @click="limparFiltro(0)">Sim</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import qs from "qs"
    import moment from "moment"
    import { Buffer } from 'buffer'

    export default {
        name: "Relatorio",
        components: {},
        data(){
            return {
                modalLimpeza: false,
                pesquisado: false,
                carregando: false,
                carregandoBusca: false,
                autenticado: true,
                clientes: '',
                token: {},
                form: {
                    dataInicialLote: moment().format('YYYY-MM-DD'),
                    dataFinalLote: moment().format('YYYY-MM-DD'),
                    cliente: this.clientes,
                    tipoDocumento: 2
                },
                autenticacao: {
                    autenticacao: false
                },
                dados: false,
                menuMobile: false,
                contagem: {},
                paginaAtual: 1, 
                totalRegistros: -1,
                totalPaginas: 1,
                contadorPaginas: 50,
                numeroExibicaoRegistros: 50,
                expiracaoInterval: null,
                horaAtual: moment().format('HH:mm'),
                contagemRegressiva: '00:00:00',
                contagemRegressivaClass: 'default',
                filtros: ''
            }
        },
        created(){
            /**
             * Realizar a autenticação
             */
            this.autenticar()

            sessionStorage.removeItem('form')
        },
        mounted() {
            /**
             * Realizar a autenticação
             */
            this.autenticar()

            /**
             * Realiza a contagem da expiração
             */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)

            /**
             * Eventos para capturar o teclado
             */
            document.addEventListener('keydown', (event) => {
                if (event.ctrlKey && event.key === 'Enter') {
                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }

                if (event.key === 'Enter') {
                    if (document.activeElement && document.activeElement.tagName.toLowerCase() === "textarea") {
                        return
                    }

                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }
            });
        },
        watch: {
            paginaAtual: {
                handler: function (novoValor, valorAntigo) {
                    if(novoValor != valorAntigo){
                        this.pesquisar()
                    }
                }
            }
        },
        computed: {
            paginas() {
                let intervalo = 2

                intervalo = this.paginaAtual == 1 ? 4 : intervalo
                intervalo = this.paginaAtual == 2 ? 3 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas ? 4 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas - 1 ? 3 : intervalo
                
                const primeiraPagina = Math.max(1,  this.paginaAtual - intervalo);
                const ultimaPagina = Math.min(this.totalPaginas,  this.paginaAtual + intervalo);
                
                return Array.from({ length: ultimaPagina - primeiraPagina + 1 }, (_, index) => primeiraPagina + index);
            }
        },
        methods: {
            /**
             * Função para realizar a autenticação da aplicação
             */
            async autenticar(){
                if(this.autenticacao.autenticado == false){
                    return false
                }

                /**
                 * Limpa os dados do URL
                 */
                window.location.hash = ''

                /**
                 * Verifica se existe o token de autenticação
                 */
                if(!sessionStorage.getItem('access_token')){
                    toastAlert('Você não está autenticado, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                         * Desloga da aplicação
                         */
                        this.sair()
                    }, 2000)

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Define os dados nas variaveis
                 */
                this.autenticacao.autenticado = true
                this.autenticacao.refresh_token = sessionStorage.getItem('refresh_token')
                this.autenticacao.access_token = sessionStorage.getItem('access_token')
                this.autenticacao.token_type = sessionStorage.getItem('token_type')

                /**
                 * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
                 *
                 * @var {string} sHeader
                 * @var {string} sPayload
                 * @var {string} sSignature
                 */
                const [sHeader, sPayload, sSignature] = this.autenticacao.access_token
                    .toString()
                    .split('.')

                /**
                 * Transforma o dado em base64 para string legível
                 *
                 * @var {Object} oDadosDescriptografados
                 */
                const oDadosDescriptografados = JSON.parse(
                    (Buffer.from(sPayload, 'base64'))
                    .toString('ascii')
                )
               
                /**
                 * Verifica se o usuario é admin
                 */
                if(oDadosDescriptografados['grupo'] && 
                    (oDadosDescriptografados['grupo'].includes('Administradores')
                    || oDadosDescriptografados['grupo'].includes('administradores'))
                ){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.admin = true

                    this.form.tipoDocumento = ''
                }

                /**
                 * Verifica se o usuario é admin
                 */
                if(oDadosDescriptografados['grupo'] && 
                    (oDadosDescriptografados['grupo'].includes('Clientes')
                    || oDadosDescriptografados['grupo'].includes('clientes'))
                ){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.clientes = true
                }

                /**
                 * Verifica se o usuario é admin
                 */
                if(oDadosDescriptografados['grupo'] && 
                    (oDadosDescriptografados['grupo'].includes('CDT')
                    || oDadosDescriptografados['grupo'].includes('cdt'))
                ){
                    this.autenticado = false
                    window.location.href = '/error'
                }

                this.autenticacao.usuario = oDadosDescriptografados.usuario
                this.autenticacao.exp = oDadosDescriptografados.exp
                this.clientes = oDadosDescriptografados.clientes ?? ''
                this.form.cliente = this.clientes

                /**
                 * Verifica se o token não está expirado
                 */
                if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
                    await this.refreshToken()
                }

                return true
            },   
            /**
             * Função para limpar o filtro
             */
            limparFiltro(iTipo){
                if(iTipo == 1){
                    this.modalLimpeza = true
                }else{
                    this.modalLimpeza = false
                    this.pesquisado = false
                    this.totalRegistros = -1
                    this.dados = false
                    this.contagem = {}
                    this.form = {
                        dataInicialLote: '',
                        dataFinalLote: '',
                        cliente: !this.autenticacao.admin ? this.clientes : '',
                        tipoDocumento: !this.autenticacao.admin ? 2 : ''
                    }
                }
            },
            /**
             * Função para pesquisar
             */
            async pesquisar(bFiltro = true, bResetarPagina = false){
                if(this.form.dataInicialLote != '' && this.form.dataFinalLote != '' && moment(this.form.dataInicialLote).isAfter(moment(this.form.dataFinalLote))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                this.carregado = true
                this.carregandoBusca = true
                this.dados = false

                if(sessionStorage.getItem('relatorio') != null && !bFiltro){
                    /**
                     * Pega os parametros do storage
                     * 
                     * @var {object} oParametros
                     */
                    const oParametros = JSON.parse(sessionStorage.getItem('relatorio'))

                    this.form.dataInicialLote = oParametros.dataInicialLote
                    this.form.dataFinalLote = oParametros.dataFinalLote
                    this.form.cliente = oParametros.cliente
                    this.form.tipoDocumento = oParametros.tipoDocumento
                    this.pagina = oParametros.pagina
                    this.numeroPorPagina = oParametros.numeroPorPagina
                }
              
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicialLote: this.form.dataInicialLote.replaceAll('-', ''),
                    dataFinalLote: this.form.dataFinalLote.replaceAll('-', ''),
                    cliente: this.form.cliente,
                    tipoDocumento: this.form.tipoDocumento,
                    pagina: this.paginaAtual,
                    numeroPorPagina: this.numeroExibicaoRegistros
                })

                sessionStorage.setItem('relatorio', JSON.stringify({
                    dataInicialLote: this.form.dataInicialLote,
                    dataFinalLote: this.form.dataFinalLote,
                    cliente: this.form.cliente,
                    tipoDocumento: this.form.tipoDocumento,
                    pagina: this.paginaAtual,
                    numeroPorPagina: this.numeroExibicaoRegistros
                }))
                
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/relatorio?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(this.form.dataInicialLote == '' && this.form.dataFinalLote == ''){
                        this.pesquisado = false
                    }else{
                        this.pesquisado = true
                    }

                    this.totalPaginas = Math.ceil(response.data.totalRegistros / this.numeroExibicaoRegistros)
                    this.totalRegistros = response.data.totalRegistros
                    this.contadorPaginas = this.numeroExibicaoRegistros * this.paginaAtual

                    if(this.contadorPaginas > response.data.totalRegistros){
                        this.contadorPaginas = response.data.totalRegistros
                    }

                    this.dados = response.data.dados
                    this.contagem.totalPaginas = this.dados.reduce((acumulador, itemAtual) => acumulador + itemAtual.totalPaginas, 0)
                    this.contagem.totalArquivos = this.dados.reduce((acumulador, itemAtual) => acumulador + itemAtual.totalArquivos, 0)
                })
                .catch((error) => {
                    this.totalRegistros = 0
                    console.log(error)
                    toastAlert(`Erro ao buscar dados${error?.response?.data?.descricao ? `: ${error?.response?.data?.descricao}` : ''}`)
                });

                this.carregandoBusca = false
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para ir para os usuarios
             */
            usuariosPagina(){
                window.location.href = '/usuarios'
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                sessionStorage.removeItem('access_token')

                window.location.href = '/'
            },
            /**
             * Função para cancular a expiracao
             */
            calcularExpiracao() {
                const dataAtualUnix = Math.floor(Date.now() / 1000)
                const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

                if(diferencaSegundos <= 10){
                    this.contagemRegressivaClass = 'danger'
                }else{
                    this.contagemRegressivaClass = 'default'
                }

                if (diferencaSegundos <= 0) {
                    clearInterval(this.expiracaoInterval)
                    this.contagemRegressiva = "Sessão expirada"
                } else {
                    const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                    const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                    const segundos = diferencaSegundos % 60

                    this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
                }
            },
            /**
             * Função para atualizar o token
             */
            async refreshToken(){
                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Define os opções da requisição
                * 
                * @var {object} oOpcoes
                */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify({ token: this.autenticacao.refresh_token })
                }

                /**
                * Realiza a requisição para buscar os detalhes do usuario
                * 
                * @var {object} oLogin
                */
                const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_AUTH}/v1/refreshToken`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!oLogin){
                    toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                        * Desloga da aplicação
                        */
                        this.sair()
                    }, 2000)

                    /**
                    * Define os dados nas variaveis
                    */
                    this.autenticacao.autenticado = false

                    return false
                }    

                if(oLogin?.access_token){
                    sessionStorage.setItem('access_token', oLogin.access_token)
                }

                /**
                * Realizar a autenticação
                */
                this.autenticar()

                /**
                * Realiza a contagem da expiração
                */
                this.calcularExpiracao()
                this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            },
            /**
             * Função para exportar o relatorio
             */
            async exportar(sTipo){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicialLote: this.form.dataInicialLote.replaceAll('-', ''),
                    dataFinalLote: this.form.dataFinalLote.replaceAll('-', ''),
                    cliente: this.form.cliente,
                    tipoDocumento: this.form.tipoDocumento
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                this.carregando = true

                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/relatorio/exportar?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    if(response.data.size == 0){
                        this.exportar(sTipo)

                        return
                    }

                    response.data = new Blob([response.data])

                    // Cria um link de download do arquivo
                    var url = URL.createObjectURL(response.data)
                    var link = document.createElement("a")
                    link.setAttribute("href", url)
                    link.setAttribute("download", `relatorio_${Date.now()}.csv`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.carregando = false
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)

                    this.carregando = false
                })
            },
            toggleMenu(){
                console.log(1)
                this.menuMobile = !this.menuMobile
            }
        }
    }
</script>

<style>
    html{
        overflow: auto!important;
        background-color: #eee!important;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .div-principal{
        height: 100vh;
        overflow: hidden;
    }

    .barra-superior{
        margin: 0;
        margin-top: 7px!important;
        height: 6rem!important;
        background: #fff!important;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0!important;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .div-cp{
        top: -16px!important;
        padding-top: 12px;
        padding: 0;
        background:#eee!important;
        position: relative;
    }

    .container-principal{
        margin: 10px 0;
        overflow-y: auto;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
        overflow-x: hidden;
    }

    .formulario{
        margin: 0 auto;
        margin-top: 1rem!important;
        position: relative;
        width: 97vw!important;
        background: #fff;
        padding: 1rem 1rem;
        border-radius: 5px!important;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 0rem!important;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        border-top: 1px solid #ddd;
        width: 97vw!important;
        padding: 1rem 2rem 0rem 2rem;
        margin-left: -1rem;
    }

    .selectize-input{
        padding: 10px 8px!important;
    }

    .selectize-control.multi > .selectize-input > *{
        width: 32%;
    }

    .selectize-input > *{
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;    
        margin: -4px 0px;
    }

    .formulario .columns{
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-top: -0.5rem!important;
        align-items: flex-end;
        padding-bottom: 15px;
    }

    .formulario .column{
        padding: 0 0.5rem!important;
        margin-top: 0rem!important;
    }

    .formulario .column.is-one-tenth,.column.is-one-tenth-tablet {
        flex: none;
        width: 10%
    }

    .formulario .label {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }

    .tabs{
        align-items: flex-end;
        margin-bottom: 0px;
        margin-left: -1rem;
        width: 97vw!important;
    }
    
    .tabs ul{
        padding-left: 2rem;
    }

    .formulario .numero{
        position: absolute;
        right: 25px;
        top: 1rem;
        font-size: 18px;
    }

    .formulario .registroAnterior, .formulario .indisponibilidade{
        border-bottom: 1px solid #eee;
    }

    .btn-rotate > .icon{
        transform: rotate(270deg);
    }

    .modal-card{
        top: 70px;
        position: absolute;
    }

    .accordion {
        width: 100%;
    }

    .accordion-item {
        border: 1px solid #ddd;
        margin-bottom: 1rem;
    }

    .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        background-color: #f1f1f1;
    }

    .accordion-icon {
        font-size: 1.2rem;
    }

    .accordion-content {
        padding: 0.5rem;
        display: none;
        border-top: 1px solid #ddd;
    }

    .accordion-content.hide {
        display: none;
    }

    .accordion-content.show {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .linha-divisoria{
        border-top: 1px solid #ccc;
        padding-left: 1.5rem;
        line-height: 0rem;
        margin: 20px 0 10px;
        width: 100%;
    }

    .linha-divisoria span {
        background-color: white;
        padding: 0 10px;
    }

    .optionEndereco:hover{
        background-color: #f5fafd;
        color: #495c68;
        cursor: pointer;
    }

    .optionEndereco{
        padding: 5px 8px;
    }

    .onusAtivo{
        color: #ff5a5a!important; 
        font-weight: bold;
    }

    .checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        line-height: 1.5;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    .checkbox:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .checkbox input:checked ~ .checkmark {
        background-color: #006598;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkbox input:checked ~ .checkmark:after {
        display: block;
    }

    .checkbox .checkmark:after {
        left: 10px;
        top: 5px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .modal-card-body{
        max-height: 70vh;
    }

    .loading-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Fundo semi-transparente */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Certifique-se de que a tela de loading está acima de outros elementos */
    }

    .loading-spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top: 4px solid #3498db; /* Cor do spinner */
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite; /* Animação de rotação */
    }

    .hidden{
        display: none!important;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .is-2 > div > .selectize-control.multi .selectize-input > div{
        width: 96%;
    }

    .dropdownContribuintes{
        display: none;
    }

    .remover{
        color: #FF5A5A;
        padding: 5px 8px;
    }

    .tabs.is-boxed li.is-active a {
        background-color: #b96082;
    }

    .tabs li.is-active a {
        color: #fff;
    }
    
    
    .column-date{
        display: flex; 
        gap: 1rem;
    }

    .column-date > span{
        margin-top: 2.25rem;
    }

    .column-date > .control{
        width: 100%;
    }

    .selectize-control.multi .selectize-input.has-items{
        padding: 6px 8px!important;
    }

    .column-table{
        padding-top: 0!important;
        padding-bottom: 0!important;
        overflow-y: auto;
    }

    .btn-limpar{
        margin-top: 1rem;
    }

    .btn-pesquisar{
        margin-top: 1rem;
        float: right;
    }

    .pagination{
        gap: 1rem;
    }

    .pagination > b{
        margin-left: -1.8rem;
        width: 6rem;
    }

    .pagination, .pagination-list{
        display: inline-flex;
    }

    .pagination-previous{
        order: 0;
    }

    .pagination-previous.is-disabled, .pagination-next.is-disabled{
        opacity: .7;
        pointer-events: none;
    }

    .column-nao-encontrado{
        justify-content: center;
        align-items: center;
    }

    .column-nao-encontrado > div{
        margin: 1rem 1rem 1.5rem 1rem;
    }

    .pagination-link.is-current{
        background-color: #000;
        border-color: #dbdbdb;
    }

    .btn-sessao{
        background: transparent;
    }

    .dropdown-menu{
        min-width: 15.3rem!important;
    }

    .dropdown-content{
        border: 1px solid #ddd
    }

    a.dropdown-item{
        padding-right: 1rem!important;
        text-align: center;
    }

    .barra-superior-mobile{
        display: none;
    }

    .menu-mobile{
        position: fixed;
        height: 100%;
        background: #fff;
        z-index: 7889;
        right: 0;
        width: 240px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid #eee;
        padding-top: 2rem;
    }

    .exportacao{
        position: absolute; 
        right: 20px;
    }

    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
    }

    input[type="date"] {
        display: block;
    }

    @media (max-width: 450px) {
        .footer{
            height: max-content;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 650px) {
        .exportacao{
            position: unset; 
            margin: 2rem 0;
        }
    }

    @media (max-width: 850px) {
        .barra-superior{
            display: none
        }
        .barra-superior-mobile{
            display: block;
        }
        .modal-card{
            max-width: 96%;
        }
    }
</style>
