<template>
    <div class="control">
        <label class="label">{{ label }} <span v-if="labelTotal" style="float: right; margin-right: 10px;">{{ labelTotal }}</span></label>
        <select 
            ref="select" 
            :multiple="multiple ?? false"
            :class=" { tabindex: label == 'Tipo do documento' }"
            :tabindex="tabindex ?? false" 
            :value="value">
            <slot></slot>
        </select>
    </div>
</template>

<script>
    import 'selectize/dist/css/selectize.css'
    import 'selectize/dist/js/standalone/selectize.js'
    import $ from 'jquery'

    export default {
    props: ['label', 'value', 'onchange', 'labelTotal', 'textoOption', 'selecionarOpcao', 'buscarCepPorEndereco', 'cancelarOnus', 'indice', 'preenchido', 'tab', 'tabUltimoCampo', 'tabindex', 'render', 'multiple'],
    data() {
        return {
            preenchidoInput: false
        }
    },
    watch: {
        /**
         * Função para verificar se o campo está preenchido
         */
        preenchido(novoValor){
            /**
             * Define o select a ser manipulado
             * 
             * @var {object} oSelect
             */
            const oSelect = this.$refs.select

            /**
             * Instancia o selectize
             * 
             * @var {object} oSelectize
             */
            const oSelectize = $(oSelect)[0].selectize

            if(novoValor == ''){
                oSelectize.$control[0].style.borderColor = '#f14668'
            }else{
                oSelectize.$control[0].style.borderColor = '#d0d0d0'
            }
        },
        /**
         * Função para definir o valor do selectize
         */
        value(novoValor){
            /**
             * Define o select a ser manipulado
             * 
             * @var {object} oSelect
             */
            const oSelect = this.$refs.select

            /**
             * Instancia o selectize
             * 
             * @var {object} oSelectize
             */
            const oSelectize = $(oSelect)[0].selectize

            if(novoValor != '' && novoValor != null && novoValor != undefined){
                oSelectize.setValue(novoValor)
            }
        }
    },
    mounted() {
        /**
         * Define o select a ser manipulado
         * 
         * @var {object} oSelect
         */
        const oSelect = this.$refs.select

        /**
         * Define as opções do selectize
         * 
         * @var {object} oSelectizeOpcoes
         */
        const oSelectizeOpcoes = $(oSelect)
            .find('option')
            .map((_, option) => ({
                value: option.value,
                text: option.text
            }))
            .get()

        /**
         * Inicializa o selectize
         * 
         * @var {array} $aSelect
         */
        const $aSelect = $(oSelect).selectize({
            options: oSelectizeOpcoes,
            plugins: this.multiple ? ['remove_button'] : [],
            placeholder: this.textoOption ?? 'Selecione uma opção',
            onBlur: async () => {
                /**
                 * Instancia o selectize
                 * 
                 * @var {object} oSelectize
                 */
                const oSelectize = $(oSelect)[0].selectize
                this.onChange(oSelectize.getValue())       

                if(oSelectize.getValue() != '' && !this.preenchido){
                    oSelectize.$control[0].style.borderColor = '#d0d0d0'
                }

                if(this.tab != undefined){
                    await this.tabCampo(this.tab)
                }

                setTimeout(() => {    
                    /**
                     * Define o próximo elemento
                     * 
                     * @var {object} oProximoElemento
                     */  
                    const oProximoElemento = $(oSelect).closest('.formulario').find('input:visible, select:visible, textarea:visible')[parseInt($(oSelect).closest('.formulario').find('.control:visible').index(oSelect.closest('.control'))) + parseInt(1)]
                    
                    if((oSelectize.getValue() != '') && oProximoElemento && !this.labelTotal){
                        oProximoElemento.focus()
                    }
                }, 100)
            },
            onDropdownOpen: () => {
                if(!this.multiple){
                    /**
                     * Instancia o selectize
                     * 
                     * @var {object} oSelectize
                     */
                    const oSelectize = $(oSelect)[0].selectize

                    if(oSelectize.getValue() == ''){
                        oSelectize.clear()  
                    }
                }

                oSelect.focus()
            }
        })

        /**
         * Instancia o selectize
         * 
         * @var {object} oSelectize
         */
        const oSelectize = $(oSelect)[0].selectize

        if(!this.multiple){
            oSelectize.clear()  
        }
        
        if(this.value != '' && this.value != null && this.value != undefined){
            oSelectize.setValue(this.value)
        }
    },
    methods: {
        /**
         * Função que é executada quando o valor do select é alterada
         */
        onChange(value) {
            this.$emit('input', value)

            this.preenchidoInput = false

            if(this.render){
                this.render()
            }

            if(this.buscarCepPorEndereco){
                this.buscarCepPorEndereco(this.indice)
            }

            if(this.selecionarOpcao){
                this.selecionarOpcao(value)
            }

            if(this.cancelarOnus){
                this.cancelarOnus(value)
            }

            if(typeof this.onchange === 'function'){
                this.onchange()
            }
        },
        /**
         * Função para limpar o selectize
         */
        clearSelectize() {
            /**
             * Instancia o selectize
             * 
             * @var {object} oSelectize
             */
            const oSelectize = $(this.$refs.select)[0].selectize
            oSelectize.clear()
        },
        /**
         * Função para atualizar as opções do selectize
         */
        updateOptions(oOptions) {
            /**
             * Instancia o selectize
             * 
             * @var {object} oSelectize
             */
            const oSelectize = $(this.$refs.select)[0].selectize
            
            oSelectize.clearOptions()

            for (const iChave in oOptions) {
                if (oOptions.hasOwnProperty(iChave)) {
                    oSelectize.addOption({ value: iChave, text: oOptions[iChave] }, false);
                }
            }

            oSelectize.refreshOptions(false)
        },
        /**
         * Função para chamar a função de tab do componente pai
         */
        async tabCampo(sValor) {
            if(this.tabUltimoCampo !== undefined){
                if(this.label == 'Possui anotações?'){
                    /**
                     * Define o select a ser manipulado
                     * 
                     * @var {object} oSelect
                     */
                    const oSelect = this.$refs.select
                    
                    /**
                     * Instancia o selectize
                     * 
                     * @var {object} oSelectize
                     */
                    const oSelectize = $(oSelect)[0].selectize

                    if(oSelectize.getValue() != 0){
                        return
                    }                    
                }

                await this.tabUltimoCampo(sValor)
            }
        }
    }
    }
</script>

<style scoped>
    .control{
        margin-bottom: -6px
    }
</style>